<template>
  <a-modal
    title="新建授信银行"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="主体企业">
              <a-select
                option-label-prop="tt"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                allow-clear
                v-decorator="[
                  'host_corporation_id',
                  {
                    rules: [
                      { required: true, message: '' },
                    ],
                  },
                ]"
                placeholder="请输入主体企业"
                :default-active-first-option="false"
                @search="this.corporationSearch"
                @change="handleCorporationChange"
              >
                <a-select-option v-for="d in corporationList" :key="d.id" :tt="d.name">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="授信银行">
              <a-select
                option-label-prop="tt"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                allow-clear
                v-decorator="[
                  'bank_id',
                  {
                    rules: [
                      { required: true, message: '' },
                    ],
                  },
                ]"
                placeholder="请输入授信银行"
                :default-active-first-option="false"
                @search="this.bankSearch"
                @change="handleBankChange"
              >
                <a-select-option v-for="d in bankList" :key="d.id" :tt="d.name">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="信用">
              <a-input-number :precision="2" style="width: 50%" v-decorator="['credit', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { corporation_list } from '@/api/trade_corporation'
import { bank_list } from '@/api/trade_bank'
import debounce from 'lodash/debounce'

// 表单字段
const fields = ['id', 'name', 'contact', 'phone', 'mail']
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    this.corporationSearch = debounce(this.handleCorporationSearch, 800)
    this.bankSearch = debounce(this.handleBankSearch, 800)
    return {
      color: '#3f3f3f',
      form: this.$form.createForm(this),
      corporationList: [],
      bankList: []
    }
  },
  created () {
    console.log('custom modal created', this.form)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
    this.handleCorporationSearch(undefined)
    this.handleBankSearch(undefined)
  },
  methods: {
    handleCorporationSearch (value) {
      corporation_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        this.corporationList = !value ? (result.length > 5 ? result.slice(0, 5) : result) : result
      })
    },
    handleCorporationChange (value) {
      console.log(value)
      if (value === undefined) {
        this.handleCorporationSearch(value)
      }
    },
    handleBankSearch (value) {
      bank_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        this.bankList = !value ? (result.length > 5 ? result.slice(0, 5) : result) : result
      })
    },
    handleBankChange (value) {
      console.log(value)
      if (value === undefined) {
        this.handleBankSearch(value)
      }
    }
  }
}
</script>

<template>
  <div>
    <a-form :form="form" >
      <a-form-item label="主体企业" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input disabled v-decorator="['host_corporation', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
      </a-form-item>
      <a-form-item label="授信银行" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input disabled v-decorator="['bank', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
      </a-form-item>
      <a-form-item label="信用" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input-number :precision="2" style="width: 50%" v-decorator="['credit', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
      </a-form-item>
      <a-form-item v-bind="buttonCol">
        <a-row>
          <a-col span="6">
            <a-button type="primary" html-type="submit" @click="handleSubmit">提交</a-button>
          </a-col>
          <a-col span="10">
            <a-button @click="handleGoBack">返回</a-button>
          </a-col>
          <a-col span="8"></a-col>
        </a-row>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
// import moment from 'moment'
import pick from 'lodash.pick'
import { corporation_credit_update } from '@/api/trade_corporation_credit'

export default {
  name: 'TableEdit',
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this),
      id: 0
    }
  },
  // beforeCreate () {
  //   this.form = this.$form.createForm(this)
  // },
  mounted () {
    this.$nextTick(() => {
      console.log('======>>>', this.record)
      this.loadEditInfo(this.record)
    })
  },
  methods: {
    handleGoBack () {
      this.$emit('onGoBack')
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((err, values) => {
        console.log('我要提交接口', values)
        if (!err) {
          // eslint-disable-next-line no-console
          console.log('Received values of form: ', values)
        }
        corporation_credit_update(values, this.id)
          .then((res) => {
            this.handleGoBack()
            console.log(res, '修改成功')
    })
      })
    },
    handleGetInfo () {

    },
    loadEditInfo (data) {
      const { form } = this
      console.log(`将加载 ${this} 信息到表单`)
      new Promise((resolve) => {
        setTimeout(resolve, 0)
      }).then(() => {
        const formData = pick(data, ['id', 'host_corporation', 'bank', 'credit'])
        console.log('formData', formData)
        this.id = formData.id
        form.setFieldsValue(formData)
      })
    }
  }
}
</script>

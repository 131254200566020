import request from '@/utils/request'

const hostCorporationCreditApi = {
  trade_host_corporation_credit_list: '/trade/host_corporation_credit/',
  trade_host_corporation_credit_create: '/trade/host_corporation_credit/',
  trade_host_corporation_credit_update: '/trade/host_corporation_credit/',
  trade_host_corporation_credit_delete: '/trade/host_corporation_credit/'
}

/**
 * 列表
 */
export function corporation_credit_list (parameter) {
  return request({
    url: hostCorporationCreditApi.trade_host_corporation_credit_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function corporation_credit_create (parameter) {
  return request({
    url: hostCorporationCreditApi.trade_host_corporation_credit_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function corporation_credit_update (parameter, corporation_id) {
  return request({
    url: hostCorporationCreditApi.trade_host_corporation_credit_update + corporation_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function corporation_credit_delete (corporation_id) {
  return request({
    url: hostCorporationCreditApi.trade_host_corporation_credit_delete + corporation_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

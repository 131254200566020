<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="5" :sm="24" v-if="false">
            <a-form-item label="主体企业">
              <a-select
                option-label-prop="tt"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                allow-clear
                v-model="queryParam.host_corporation_id"
                placeholder="请输入主体企业"
                :default-active-first-option="false"
                @search="this.corporationSearch"
                @change="handleCorporationChange"
              >
                <a-select-option v-for="d in corporationList" :key="d.id" :tt="d.name">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="主体企业">
              <a-input v-model="queryParam.host_corporation_name" placeholder="请输入企业名称" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="5" :sm="24" v-if="false">
            <a-form-item label="授信银行">
              <a-select
                option-label-prop="tt"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                allow-clear
                v-model="queryParam.bank_id"
                placeholder="请输入授信银行"
                :default-active-first-option="false"
                @search="this.bankSearch"
                @change="handleBankChange"
              >
                <a-select-option v-for="d in bankList" :key="d.id" :tt="d.name">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="授信银行">
              <a-input v-model="queryParam.bank_name" placeholder="请输入银行名称" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
            </span>
          </a-col>
          <a-col :md="2" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="handleAdd">新建</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="money_render" slot-scope="text" style="text-align: right;">
        {{ (text / 1).toFixed(2) | numberFormat }}
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical" />
          <a-popconfirm
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelet(record)"
          >
            <a>删除</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>
    <create-form
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '../modules/CreateForm'
import { corporation_credit_list, corporation_credit_create, corporation_credit_delete } from '@/api/trade_corporation_credit'
import debounce from 'lodash/debounce'
import { corporation_list } from '@/api/trade_corporation'
import { bank_list } from '@/api/trade_bank'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm
  },
  data () {
    this.corporationSearch = debounce(this.handleCorporationSearch, 800)
    this.bankSearch = debounce(this.handleCorporationSearch, 800)
    return {
      loading: false,
      confirmLoading: false,
      dictionaries: Dictionaries,
      // 创建窗口控制
      visible: false,
      mdl: {},
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '主体企业',
          dataIndex: 'host_corporation',
          width: 200,
          ellipsis: true
        },
        {
          title: '授信银行',
          dataIndex: 'bank',
          width: 180,
          ellipsis: true
        },
        {
          title: '信用',
          dataIndex: 'credit',
          scopedSlots: { customRender: 'money_render' },
          align: 'right',
          width: 300,
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '200px',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('loadData.parameter', parameter)
        return corporation_credit_list(Object.assign(parameter, this.queryParam))
          .then(res => {
            console.log(res.data, '我是数据列表@')
            return res.data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],
      corporationList: [],
      bankList: [],
      // custom table alert & rowSelection
      options: {
        alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    this.tableOption()
    this.handleCorporationSearch(undefined)
    this.handleBankSearch(undefined)
  },
  methods: {
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    handleCorporationSearch (value) {
      corporation_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        this.corporationList = !value ? (result.length > 5 ? result.slice(0, 5) : result) : result
      })
    },
    handleCorporationChange (value) {
      console.log(value)
      if (value === undefined) {
        this.handleCorporationSearch(value)
      }
    },
    handleBankSearch (value) {
      bank_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        this.bankList = !value ? (result.length > 5 ? result.slice(0, 5) : result) : result
      })
    },
    handleBankChange (value) {
      console.log(value)
      if (value === undefined) {
        this.handleBankSearch(value)
      }
    },
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false

      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      this.$emit('onEdit', record)
      // this.$router.push({ path: '/trade/bank/' + record.id })
    },
    handleDelet (record) {
      corporation_credit_delete(record.id).then((res) => {
        console.log(res)
        console.log('删除成功------')

        this.$refs.table.refresh()
      })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
            // 新增
          corporation_credit_create(values).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()
            console.log('删除成功------')
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    }
  }
}
</script>
